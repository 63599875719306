import React from "react"
import { Grommet, Box, Heading } from "grommet"

// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Grommet>
    <SEO title="Home" />

    <Layout>
      <Box>
        <Heading level="2" textAlign="center" margin="none">
          Waite & Clancy Consulting
        </Heading>
        <Heading level="3" color="dark-5" textAlign="center" margin="xsmall">
          Growth Agency | Technologists
        </Heading>
      </Box>
    </Layout>
  </Grommet>
)

export default IndexPage
